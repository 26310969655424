<template>
  <v-alert
    border="top"
    colored-border
    color="primary"
    elevation="2"
    v-if="selectedSurvey"
    height="auto"
    class="mt-6 mb-6"
  >
    <v-carousel
      :hide-delimiter-background="true"
      :hide-delimiters="true"
      :show-arrows="
        !(this.page == 0 || this.page == this.surveyQuestions.length + 1 || !this.isCurrentQuestionAnswered)
      "
      height="auto"
      v-model="page"
    >
      <v-carousel-item eager>
        <h3 class="headline" align="center">
          {{ selectedSurvey ? selectedSurvey.name : "" }}
        </h3>
        <p align="center">
          <span v-html="selectedSurvey ? selectedSurvey.description : ''"> </span>
          <br /><br />
          <v-btn
            class="primary"
            @click="
              takingSurvey = true;
              if (isPendingSurvey) {
                page = firstNonAnsweredQuestion;
              } else {
                page++;
              }
            "
            >{{ this.isPendingSurvey ? "Continue Survey" : "Take Survey" }}</v-btn
          >
        </p>
      </v-carousel-item>
      <v-carousel-item v-for="(question, index) in surveyQuestions" :key="index" eager>
        <v-container>
          <v-row justify="center">
            <v-col cols="6">
              <h2
                class="headline"
                v-if="surveyQuestions && surveyQuestions.length > 0"
                v-text="question.description"
                :rules="[v => !!v || 'Required']"
                align="center"
              ></h2>
            </v-col>
          </v-row>

          <v-row
            v-if="question && question.surveyQuestionType && question.surveyQuestionType.name == 'CHECK_BOXES'"
            justify="center"
          >
            <v-col cols="6">
              <v-checkbox
                v-for="(option, index) in question.options"
                v-model="question.answers"
                :value="option"
                :key="index"
                :label="option.description"
                :ref="'option' + index"
                dense
                class="mt-n3"
                @change="dirtySurvey = true"
              >
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row
            v-if="question && question.surveyQuestionType && question.surveyQuestionType.name == 'MULTIPLE_CHOICE'"
            justify="center"
          >
            <v-col cols="6">
              <v-radio-group v-model="question.answers">
                <v-radio
                  v-for="(option, index) in question.options"
                  :value="option"
                  :key="index"
                  :label="option.description"
                  :ref="'option' + index"
                  dense
                  @change="dirtySurvey = true"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="
              surveyQuestions && question && question.surveyQuestionType && question.surveyQuestionType.name == 'TEXT'
            "
            justify="center"
          >
            <v-col cols="6">
              <v-textarea
                @change="dirtySurvey = true"
                v-model="question.textAnswer"
                :hint="question.textAnswer ? 'Characters left: ' + (300 - question.textAnswer.length) : ''"
                maxlength="300"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn @click="page++" :disabled="!isCurrentQuestionAnswered" class="primary">Next</v-btn>
          </v-row>
        </v-container>
      </v-carousel-item>
      <v-carousel-item v-if="takingSurvey" @change="submitAnswers()">
        <h3 class="headline" align="center">
          {{ selectedSurvey ? completedTitle : "" }}
        </h3>
        <h5 align="center">
          {{ selectedSurvey ? completedDescription : "" }}
        </h5>
        <p align="center">
          <br />
          <v-btn
            class="primary"
            @click="
              if (participantHasPendingSurveys) {
                fetchNextSurvey().then(() => {
                  page = 0;
                });
              } else {
                selectedSurvey = null;
              }
            "
            :loading="loading"
            >{{ participantHasPendingSurveys ? "Next Survey" : "Close" }}</v-btn
          >
        </p>
      </v-carousel-item>
    </v-carousel>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  name: "Survey",
  props: {},
  data: () => ({
    participantHasPendingSurveys: false,
    selectedSurvey: null,
    availableSurveys: [],
    pendingSurveys: [],
    surveyQuestions: [],
    takingSurvey: false,
    isPendingSurvey: false,
    firstNonAnsweredQuestion: 0,
    page: 0,
    dirtySurvey: false,
    loading: true,
    completedTitle: "",
    completedDescription: ""
  }),
  methods: {
    checkIfParticipantHasPendingSurveys() {
      this.participantHasPendingSurveys = false;

      return ApiService.post("/api/surveys/search/eligible", {}).then(({ data }) => {
        let promises = [];
        data.content.forEach(elem => {
          elem.status = "UNKNOWN";
          promises.push(
            ApiService.get(
              "/api/surveys/submissionStatusForSurveyAndParticipant/" + elem.id + "/" + this.selectedParticipant.id
            ).then(({ data }) => {
              elem.surveySubmissionStatusType = data.name;
            })
          );
        });
        return Promise.all(promises).then(() => {
          data.content.forEach(elem => {
            if (elem.surveySubmissionStatusType == "PARTIAL" || elem.surveySubmissionStatusType == "NO_ANSWERS") {
              this.participantHasPendingSurveys = true;
            }
          });
        });
      });
    },
    fetchSelectedSurveyData() {
      if (this.selectedSurvey) {
        return this.fetchSurveyQuestions().then(() => {
          return this.fetchParticipantAnswers()
            .then(({ data }) => {
              this.loadParticipantAnswersIntoForm(data);
            })
            .catch(error => console.log(error))
            .then(() => {
              this.$forceUpdate();
            });
        });
      } else {
        return Promise.resolve();
      }
    },
    fetchAvailableSurvey() {
      return ApiService.post("/api/surveys/search/eligible/?size=1", {}).then(({ data }) => {
        this.$set(this, "availableSurveys", data.content);
      });
    },
    fetchPendingSurvey() {
      return ApiService.post("/api/surveys/search/eligible/partial?size=1", {}).then(({ data }) => {
        this.$set(this, "pendingSurveys", data.content);
      });
    },
    saveAnswers(submitAnswers) {
      if (!this.selectedSurvey) {
        return;
      }

      let postForm = {
        answers: []
      };

      this.surveyQuestions.forEach(question => {
        let newAnswer = { surveyQuestion: { id: question.id } };
        switch (question.surveyQuestionType.name) {
          case "CHECK_BOXES":
            newAnswer.selectedOptions = [];
            if (question && question.answers) {
              question.answers.forEach(answer => {
                newAnswer.selectedOptions.push({
                  id: answer.id
                });
              });
            }
            break;
          case "MULTIPLE_CHOICE":
            if (question && question.answers) {
              newAnswer.selectedOptions = [{ id: question.answers.id }];
            }
            break;
          case "TEXT":
            newAnswer.textAnswer = question.textAnswer;
            break;
        }
        postForm.answers.push(newAnswer);
        postForm.submitAnswers = submitAnswers;
      });
      return ApiService.post("/api/surveys/submitAnswers/" + this.selectedSurvey.id, postForm)
        .then(() => {})
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.dirty = false;
          this.loading = false;
        });
    },
    fetchSurveyQuestions() {
      return ApiService.get("/api/surveyQuestions/bySurveyId/" + this.selectedSurvey.id).then(({ data }) => {
        this.surveyQuestions = data.sort((a, b) => a.rank - b.rank);
        this.surveyQuestions.forEach(question => {
          this.$set(
            question,
            "options",
            question.options.sort((a, b) => a.rank - b.rank)
          );

          this.surveyQuestions.forEach(question => {
            switch (question.surveyQuestionType.name) {
              case "MULTIPLE_CHOICE":
                this.$set(question, "answers", null);
                break;
              case "CHECK_BOXES":
                this.$set(question, "answers", []);
                break;
              case "TEXT":
                this.$set(question, "textAnswer", "");
                break;
            }
          });
        });
      });
    },
    fetchParticipantAnswers() {
      return ApiService.get(
        "/api/surveys/answersBySurveyAndParticipant/" + this.selectedSurvey.id + "/" + this.selectedParticipant.id
      );
    },
    loadParticipantAnswersIntoForm(data) {
      if (data && data.length > 0) {
        for (let answer of data) {
          let questionIndex = this.surveyQuestions.findIndex(elem => elem.id == answer.surveyQuestion.id);
          let question = this.surveyQuestions[questionIndex];
          let optionIndex;
          switch (question.surveyQuestionType.name) {
            case "MULTIPLE_CHOICE":
              optionIndex = question.options.findIndex(elem => elem.description == answer.answer);

              question.answers = question.options[optionIndex];
              break;
            case "CHECK_BOXES":
              if (!question.answers) {
                question.answers = [];
              }
              optionIndex = question.options.findIndex(elem => elem.description == answer.answer);
              question.answers.push(question.options[optionIndex]);
              break;
            case "TEXT":
              question.textAnswer = answer.answer;
              break;
          }
        }
      }
    },
    fetchNextSurvey() {
      this.loading = true;
      return this.fetchPendingSurvey()
        .finally(() => {
          if (this.pendingSurveys && this.pendingSurveys.length == 0) {
            return this.fetchAvailableSurvey().finally(() => {
              if (this.availableSurveys && this.availableSurveys.length > 0) {
                this.$set(this, "selectedSurvey", Object.assign({}, this.availableSurveys[0]));
              }
            });
          } else {
            this.$set(this, "selectedSurvey", Object.assign({}, this.pendingSurveys[0]));
            this.isPendingSurvey = true;
          }
        })
        .then(() => {
          return this.fetchSelectedSurveyData().then(() => {
            if (this.isPendingSurvey) {
              let lastAnswerIndex = this.surveyQuestions.findIndex(
                question =>
                  (!question.answers || question.answers.length == 0) &&
                  (!question.textAnswer || question.textAnswer == "")
              );
              this.firstNonAnsweredQuestion = lastAnswerIndex + 1;
            }
            this.loading = false;
          });
        });
    }
  },
  mounted() {
    this.fetchNextSurvey();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isCurrentQuestionAnswered() {
      if (this.isIntroPage || this.isFinalPage) {
        return true;
      }
      let currentQuestion = this.surveyQuestions[this.page - 1];
      if (!currentQuestion.required) {
        return true;
      }
      if (
        currentQuestion.surveyQuestionType.name == "CHECK_BOXES" &&
        currentQuestion.answers &&
        currentQuestion.answers.length > 0
      ) {
        return true;
      }

      if (currentQuestion.surveyQuestionType.name == "MULTIPLE_CHOICE" && currentQuestion.answers) {
        return true;
      }

      if (
        currentQuestion.surveyQuestionType.name == "TEXT" &&
        currentQuestion.textAnswer &&
        currentQuestion.textAnswer.length > 0
      ) {
        return true;
      }
      return false;
    },
    isIntroPage() {
      return this.page == 0;
    },
    isFinalPage() {
      return this.surveyQuestions && this.page == this.surveyQuestions.length + 1;
    },
    isQuestionPage() {
      return !(this.isIntroPage || this.isFinalPage);
    }
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        if (this.surveyQuestions && this.surveyQuestions.length > 0 && this.isFinalPage) {
          this.loading = true;
          this.completedTitle = this.selectedSurvey.completedTitle;
          this.completedDescription = this.selectedSurvey.completedDescription;

          this.saveAnswers(true).then(() => {
            this.checkIfParticipantHasPendingSurveys().then(() => {
              this.loading = false;
              if (!this.participantHasPendingSurveys) {
                setTimeout(() => {
                  this.selectedSurvey = null;
                }, 20000);
              }
            });
          });
        } else if (this.page > 0 && this.dirtySurvey) {
          this.saveAnswers(false);
          this.dirtySurvey = false;
        }
      }
    }
  }
};
</script>
