<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">
        <v-btn
          v-if="
            hasAccess({
              participantTypes: [
                '100',
                '500',
                '510',
                '520',
                '530',
                '600',
                '610',
                '620',
                '700',
                '710',
                '720',
                '730',
                '800',
                '810',
                '820',
                '830'
              ],
              programs: [env.VUE_APP_PROGRAM_CPR_KEY, env.VUE_APP_PROGRAM_CCPR_KEY]
            })
          "
          color="success"
          :to="{ name: 'cprClaims' }"
          >Start a new Claim</v-btn
        >
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div style="width: 115px; display: inline-block" v-bind="attrs" v-on="on">
              <FiscalYearField
                dense
                hide-details
                label="Fiscal Year"
                hint="Fiscal Year"
                persistent-hint
                solo-inverted
                dark
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                :client="selectedClient"
                @inputObj="selectedFiscalYear = $event"
                use-store
              >
              </FiscalYearField>
            </div>
          </template>
          Fiscal Year
        </v-tooltip>
      </v-col>
    </v-row>
    <v-alert border="top" colored-border color="primary" elevation="2">
      <p v-if="selectedProgram.programInformation" v-html="selectedProgram.programInformation"></p>
      <h3 v-else class="headline">Welcome to Sony Rebate Center!</h3>
    </v-alert>
    <Survey />

    <template v-if="hasAccess({ participantTypes: ['100'], programs: [env.VUE_APP_PROGRAM_EUR_KEY] })">
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="6">
              <AdminParticipantsWidget></AdminParticipantsWidget>
            </v-col>
            <v-col cols="6">
              <AdminPromotionsWidget></AdminPromotionsWidget>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedForWeekBarChart></ClaimsSubmittedForWeekBarChart>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ClaimsSubmittedByOrganizationBarChart></ClaimsSubmittedByOrganizationBarChart>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12">
              <AdminClaimsWidget></AdminClaimsWidget>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasAccess({ participantTypes: ['520', '530', '620', '720', '730', '820', '830', '930'] })">
      <v-row>
        <v-col :cols="6">
          <ParticipantEarnedWidget
            :to="{ name: 'cprMyClaims', query: { tab: 0 } }"
            height="100%"
          ></ParticipantEarnedWidget>
        </v-col>
        <v-col :cols="6">
          <ParticipantPendingPaymentWidget
            :to="{ name: 'cprMyClaims', query: { tab: 1 } }"
            height="100%"
          ></ParticipantPendingPaymentWidget>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasAccess({ participantTypes: ['300'] })">
      <v-row>
        <v-col cols="12">
          <AccountManagersWidget height="100%"></AccountManagersWidget>
        </v-col>
      </v-row>
    </template>

    <template>
      <v-row>
        <v-col
          :cols="hasAccess({ isApprovingAccountManager: true }) || selectedParticipant.reportToParticipant ? 4 : 0"
          v-if="hasAccess({ participantTypes: ['400'] })"
        >
          <ReportToWidget class="mb-6"></ReportToWidget>
          <AccountManagerRegistrationWidget
            v-if="hasAccess({ isApprovingAccountManager: true })"
          ></AccountManagerRegistrationWidget>
        </v-col>
        <v-col
          :cols="hasAccess({ isApprovingAccountManager: false }) && !selectedParticipant.reportToParticipant ? 12 : 8"
          v-if="hasAccess({ participantTypes: ['400'] })"
        >
          <AccountManagerOrganizationsWidget height="100%"></AccountManagerOrganizationsWidget>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasAccess({ participantTypes: ['500', '510', '800', '810', '900', '910'] })">
      <v-row>
        <v-col cols="4">
          <PrincipalCompanyWidget></PrincipalCompanyWidget>
        </v-col>
        <v-col cols="8"> </v-col>
      </v-row>
    </template>
    <template v-if="hasAccess({ participantTypes: ['520', '530', '620', '720', '730', '820', '830', '930'] })">
      <v-row>
        <v-col cols="1"> </v-col>
        <v-col cols="10">
          <ParticipantClaimsWidget></ParticipantClaimsWidget>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </template>
    <v-dialog :value="missingPreferredAwardVehicle && showPreferredAwardVehicleDialog" max-width="800" persistent>
      <v-card>
        <v-card-title>Good News!</v-card-title>
        <v-card-text>
          <p>
            There are multiple ways in which you can be paid out!
          </p>
          <p>
            Please update your preferred payout method within your
            <router-link :to="{ name: 'profile' }">profile</router-link> page.
          </p>
        </v-card-text>
        <v-card-actions>
          <span>
            <v-btn color="primary" :to="{ name: 'profile' }">Profile</v-btn>
          </span>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="showPreferredAwardVehicleDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ClaimsSubmittedByOrganizationBarChart from "../../gapp-components/components/charts/ClaimsSubmittedByOrganizationBarChart.vue";
import ClaimsSubmittedForWeekBarChart from "../../gapp-components/components/charts/ClaimsSubmittedForWeekBarChart.vue";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import Survey from "../../gapp-components/components/surveys/Survey.vue";
import AccountManagerOrganizationsWidget from "../cpr/widget/AccountManagerOrganizationsWidget.vue";
import AccountManagerRegistrationWidget from "../cpr/widget/AccountManagerRegistrationWidget.vue";
import AccountManagersWidget from "../cpr/widget/AccountManagersWidget.vue";
import AdminClaimsWidget from "../cpr/widget/AdminClaimsWidget.vue";
import AdminParticipantsWidget from "../cpr/widget/AdminParticipantsWidget.vue";
import AdminPromotionsWidget from "../cpr/widget/AdminPromotionsWidget.vue";
import ParticipantClaimsWidget from "../cpr/widget/ParticipantClaimsWidget.vue";
import ParticipantEarnedWidget from "../cpr/widget/ParticipantEarnedWidget.vue";
import ParticipantPendingPaymentWidget from "../cpr/widget/ParticipantPendingPaymentWidget.vue";
import PrincipalCompanyWidget from "../cpr/widget/PrincipalCompanyWidget.vue";
import ReportToWidget from "../cpr/widget/ReportToWidget.vue";

import moment from "moment-timezone";

export default {
  name: "EurDashboard",
  metaInfo: {
    title: "Dashboard"
  },
  components: {
    FiscalYearField,
    AdminParticipantsWidget,
    AdminPromotionsWidget,
    ClaimsSubmittedForWeekBarChart,
    ClaimsSubmittedByOrganizationBarChart,
    AdminClaimsWidget,
    ParticipantEarnedWidget,
    ParticipantPendingPaymentWidget,
    AccountManagersWidget,
    ReportToWidget,
    AccountManagerRegistrationWidget,
    AccountManagerOrganizationsWidget,
    PrincipalCompanyWidget,
    ParticipantClaimsWidget,
    Survey
  },
  data: () => ({
    env: null,
    fiscalYearStart: null,
    fiscalYearEnd: null,
    availableAwardVehicles: [],
    showPreferredAwardVehicleDialog: true,
    selectedFiscalYear: {},
    showAccountManagerWidget: false
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", []);
    let year = new moment().year();
    let fiscalYearStartMoment = moment(
      year + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
      "YYYY-MM-DD"
    );
    if (fiscalYearStartMoment.isAfter(new Date())) {
      fiscalYearStartMoment = moment(
        year - 1 + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
        "YYYY-MM-DD"
      );
    }

    this.fiscalYearStart = fiscalYearStartMoment.format("YYYY-MM-DDT00:00:00.000Z");
    this.fiscalYearEnd = fiscalYearStartMoment
      .add(1, "years")
      .add(-1, "days")
      .format("YYYY-MM-DDT00:00:00.000Z");

    this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant).then(avs => {
      this.availableAwardVehicles = avs;
    });

    this.$api
      .post("/api/relatedParticipants/search", {
        relatedParticipant: { id: this.selectedParticipant.id }
      })
      .then(({ data }) => {
        if (data.content && data.content.length > 0) {
          let anyOrganizationIsZd = data.content.some(relatedParticipant => {
            if (relatedParticipant.organization.organizationType.organizationTypeKey == "ZD") {
              return true;
            }
          });
          if (anyOrganizationIsZd && this.selectedParticipant.participantType.participantTypeKey == "400") {
            this.showAccountManagerWidget = true;
          }
        }
      });
  },
  methods: {
    hasAccess(obj) {
      if (obj.participantTypes && obj.participantTypes.length > 0) {
        if (!obj.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)) {
          return false;
        }
      }

      if (obj.organizationTypeKeys && obj.organizationTypeKeys.length > 0) {
        if (
          !obj.organizationTypeKeys.includes(this.selectedParticipant.organization.organizationType.organizationTypeKey)
        ) {
          return false;
        }
      }

      if (obj.programs && obj.programs.length > 0) {
        if (!obj.programs.includes(this.selectedProgram.programKey)) {
          return false;
        }
      }

      if (obj.isApprovingAccountManager != null) {
        return obj.isApprovingAccountManager == this.showAccountManagerWidget;
      }
      return true;
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"]),
    missingPreferredAwardVehicle() {
      return !this.selectedParticipant.preferredAwardVehicle && this.availableAwardVehicles.length > 1;
    }
  }
};
</script>
