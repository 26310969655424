<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Top Performing
        <span v-if="periodStart && periodEnd">
          ({{ periodStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ periodEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="quarter" class="mr-2" color="primary">
        <v-btn small>
          Q1
        </v-btn>
        <v-btn small>
          Q2
        </v-btn>
        <v-btn small>
          Q3
        </v-btn>
        <v-btn small>
          Q4
        </v-btn>
        <v-btn small>
          FY{{
            selectedFiscalYear && selectedFiscalYear.fiscalYear
              ? selectedFiscalYear.fiscalYear.toString().substr(-2)
              : ""
          }}
        </v-btn></v-btn-toggle
      >
      <v-btn-toggle v-model="currency" class="mr-2" color="primary">
        <v-btn small>
          USA
        </v-btn>
        <v-btn small>
          CAN
        </v-btn>
      </v-btn-toggle>
      <v-menu offset-y :close-on-content-click="false" eager>
        <template v-slot:activator="{ on: onMenu }" v-if="showPromotionTypeFilter || showBusinessUnitFilter">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onMenu, ...onTooltip }" class="mr-2" x-small fab>
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Filters") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Filters") }}</v-subheader>
            <v-list-item selectable v-if="showPromotionTypeFilter">
              <v-list-item-content>
                <PromotionTypesField
                  dense
                  v-model="filterByPromotionTypes"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Promotion Types')"
                  @input="onSubmit"
                  :excludePromotionTypeNames="['Dealer Rebate']"
                ></PromotionTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="showBusinessUnitFilter">
              <v-list-item-content>
                <v-select
                  dense
                  v-model="filterByPromotionBusinessUnit"
                  :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Business Unit')"
                  :items="businessUnitItems"
                  clearable
                  @click:clear="onSubmit"
                  @change="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            x-small
            fab
            v-bind="attrs"
            v-on="on"
            @click="generateReport()"
            :disabled="loading || isGeneratingReport"
          >
            <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
            <v-progress-circular indeterminate color="primary" v-if="isGeneratingReport"></v-progress-circular>
          </v-btn>
        </template>
        <span>Export Report</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>{{ $i18n.translate("Promotions") }}</v-tab>
          <v-tab>
            {{ $i18n.translate("Customer") }}
          </v-tab>
          <v-tab>{{ $i18n.translate("Participant") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
            :headers="headers.promotion"
            :items="items.promotion"
            :items-per-page="5"
            :loading="loading"
            :options.sync="claimTopPromotionOptions"
          >
            <template v-slot:item.totalPayout="{ item }">
              {{ !item.totalPayout ? 0 : item.totalPayout | toCurrency }}
            </template>
            <template v-slot:no-data>
              No promotions for the selected period and country
            </template>
            <template v-slot:item.promotionKey="{ item }">
              <router-link
                :to="{
                  name: 'allClaimTypesReporting',
                  params: getParamsForPromotions(item.promotionId)
                }"
              >
                {{ item.promotionKey }}
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :headers="headers.organization"
            :items="items.organization"
            :items-per-page="5"
            :loading="loading"
            :options.sync="claimTopOrganizationOptions"
          >
            <template v-slot:item.totalPayout="{ item }">
              {{ !item.totalPayout ? 0 : item.totalPayout | toCurrency }}
            </template>
            <template v-slot:no-data>
              No resellers for the selected period and country
            </template>
            <template v-slot:item.organizationKey="{ item }">
              <router-link
                :to="{
                  name: 'allClaimTypesReporting',
                  params: getParamsForOrganizations(item.organizationId)
                }"
              >
                {{ item.organizationKey }}
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :headers="headers.participant"
            :items="items.participant"
            :items-per-page="5"
            :loading="loading"
            :options.sync="claimTopParticipantOptions"
          >
            <template v-slot:item.totalPayout="{ item }">
              {{ !item.totalPayout ? 0 : item.totalPayout | toCurrency }}
            </template>
            <template v-slot:no-data>
              No participants for the selected period and country
            </template>
            <template v-slot:item.participantName="{ item }">
              <router-link
                :to="{
                  name: 'allClaimTypesReporting',
                  params: getParamsForParticipants(item.participantId)
                }"
              >
                {{ item.participantName }}
              </router-link>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "ClaimTopPromotionWidget",
  data() {
    return {
      tab: 0,
      isGeneratingReport: false,
      quarter: null,
      currency: null,
      periodStart: null,
      periodEnd: null,
      headers: {
        promotion: [
          {
            value: "promotionKey",
            text: "Number"
          },
          {
            value: "name",
            text: "Title"
          },
          {
            value: "promotionType",
            text: "Promotion Type",
            class: "v-data-table-nowrap-header"
          },
          {
            value: "totalClaim",
            text: "Claims",
            class: "v-data-table-nowrap-header",
            align: "right"
          },
          {
            value: "totalPayout",
            text: "Earnings",
            class: "v-data-table-nowrap-header",
            align: "right"
          }
        ],
        organization: [
          {
            value: "organizationKey",
            text: "Company #",
            class: "v-data-table-nowrap-header"
          },
          {
            value: "organizationName",
            text: "Company Name",
            class: "v-data-table-nowrap-header"
          },
          {
            value: "organizationType",
            text: "Company Type",
            class: "v-data-table-nowrap-header"
          },
          {
            value: "totalClaim",
            text: "Claims",
            class: "v-data-table-nowrap-header",
            align: "right"
          },
          {
            value: "totalPayout",
            text: "Earnings",
            class: "v-data-table-nowrap-header",
            align: "right"
          }
        ],
        participant: [
          {
            value: "participantName",
            text: "Participant Name"
          },
          {
            value: "organizationKey",
            text: "Company #"
          },
          {
            value: "organizationName",
            text: "Company Name"
          },
          {
            value: "organizationType",
            text: "Company Type"
          },

          {
            value: "totalClaim",
            text: "Claims",
            class: "v-data-table-nowrap-header",
            align: "right"
          },
          {
            value: "totalPayout",
            text: "Earnings",
            class: "v-data-table-nowrap-header",
            align: "right"
          }
        ]
      },
      items: {
        promotion: [],
        organization: [],
        participant: []
      },
      endpoints: {
        0: ["/api/payouts/search/byTopPromotion"],
        1: ["/api/payouts/search/byTopOrganization"],
        2: ["/api/payouts/search/byTopParticipant"]
      },
      claimTopPromotionOptions: {
        page: 1,
        mustSort: false
      },
      claimTopOrganizationOptions: {
        page: 1,
        mustSort: false
      },
      claimTopParticipantOptions: {
        page: 1,
        mustSort: false
      },
      loading: false,
      filterByPromotionTypes: [],
      filterByPromotionBusinessUnit: undefined,
      businessUnitItems: ["B2B", "MEDICAL", "MEDIA", "PRO IMAGING"]
    };
  },
  methods: {
    async getData() {
      await Promise.all([0, 1, 2].map(tab => this.fetchDataForTab(tab)));
    },
    async fetchDataForTab(currentTab) {
      let options = this.getCriteriaForCurrentTab(currentTab);

      // Build criteria string based on sort options
      let criteria = this.getCriteriaBasedOnSortByAndSortDesc(options);

      switch (this.selectedParticipant.participantType.participantTypeKey) {
        case "100":
        case "200":
          await this.fetchData(criteria, {}, currentTab);
          break;
        case "300":
          await this.getFiltersForRM().then(filters => {
            this.fetchData(criteria, filters, currentTab);
          });
          break;
        case "400":
          await this.getFiltersForAM().then(filters => {
            this.fetchData(criteria, filters, currentTab);
          });
          break;
      }
    },

    getCriteriaBasedOnSortByAndSortDesc(options) {
      const { sortBy = [], sortDesc = [] } = options || {};
      if (sortBy.length > 0 && sortDesc.length > 0) {
        return `?nestedSort=${sortBy.join(",")},${sortDesc.join(",")}`;
      } else {
        return "";
      }
    },

    getCriteriaForCurrentTab(currentTab) {
      switch (currentTab) {
        case 0:
          return this.claimTopPromotionOptions;
        case 1:
          return this.claimTopOrganizationOptions;
        case 2:
          return this.claimTopParticipantOptions;
        default:
          return null;
      }
    },

    getFiltersForAM() {
      return this.getOrganizationsManagedByParticipant(this.selectedParticipant).then(data => {
        if (data.length == 0) {
          return null;
        }

        return {
          organizations: data.map(org => {
            return { id: org.id };
          })
        };
      });
    },

    getFiltersForRM() {
      return this.$api
        .post("/api/organizations/byIndirectParticipant", {
          participant: { id: this.selectedParticipant.id },
          relationDescription: "account managed by"
        })
        .then(({ data }) => {
          if (data.length == 0) {
            return null;
          }

          return {
            organizations: data.map(org => {
              return { id: org.id };
            })
          };
        });
    },

    getOrganizationsManagedByParticipant(participant) {
      return this.$api
        .post("/api/relatedParticipants/search", { relatedParticipant: { id: participant.id } })
        .then(({ data }) => {
          let organizations = [];
          let accountManagedByRelationships = data.content.filter(
            elem => elem.description.trim().toLowerCase() == "account managed by"
          );
          for (let relationship of accountManagedByRelationships) {
            organizations.push({ id: relationship.organization.id });
          }
          return organizations;
        });
    },

    async fetchData(criteria, filters, currentTab) {
      this.loading = true;

      if (!filters) {
        filters = {};
      }
      filters.claimApproved = true;
      filters.completelyPaid = true;
      filters.claimInvoiceDateAfter = this.periodStart;
      filters.claimInvoiceDateBefore = this.periodEnd;
      filters.currency = {
        name: this.currency == "0" ? "USD" : "CAD"
      };
      filters.promotionTypes = this.getPromotionTypesFilter();
      filters.promotionCustomField1 = this.filterByPromotionBusinessUnit;

      const endpoints = this.endpoints[currentTab];
      if (!endpoints) {
        console.warn(`No endpoints defined for index: ${currentTab}`);
        this.loading = false;
        return;
      }

      const fetchPromises = endpoints.map(async endpoint =>
        this.$api.post(endpoint + criteria, filters).then(({ data }) => {
          const key = endpoint
            .split("/")
            .pop()
            .replace("byTop", "")
            .toLowerCase();
          this.items[key] = data.filter(item => item.totalPayout != null && item.totalPayout > 0);
        })
      );
      await Promise.all(fetchPromises).finally(() => {
        this.loading = false;
      });
    },
    setDefaultCurrency() {
      if (
        this.selectedParticipant &&
        this.selectedParticipant.bank &&
        this.selectedParticipant.bank.currency &&
        this.selectedParticipant.bank.currency.name
      ) {
        switch (this.selectedParticipant.bank.currency.name) {
          case "USD":
            this.currency = 0;
            break;
          case "CAD":
            this.currency = 1;
            break;
          default:
            this.currency = 0;
            break;
        }
      } else {
        this.currency = 0;
      }
    },

    onSubmit() {
      this.getData();
    },

    getPromotionTypesFilter() {
      let promotionTypes = null;
      if (this.filterByPromotionTypes.length > 0) {
        promotionTypes = this.filterByPromotionTypes.map(promotionType => {
          return {
            id: promotionType.id
          };
        });
      }
      return promotionTypes;
    },
    generateReport() {
      let postForm = {
        dateFrom: moment(this.periodStart).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        dateTo: moment(this.periodEnd).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        currency: {
          name: this.currency == 1 ? "CAD" : "USD"
        },
        promotionTypes: this.getPromotionTypesFilter()
      };
      let reportKey = null;
      let participantTypeKey = this.selectedParticipant.participantType.participantTypeKey;
      let suffix = participantTypeKey == 300 || participantTypeKey == 400 ? participantTypeKey : "";
      switch (this.tab) {
        case 0:
          reportKey = "TopPerformingSalesNews" + suffix;
          break;
        case 1:
          reportKey = "TopPerformingResellers" + suffix;
          break;
        case 2:
          reportKey = "TopPerformingParticipants" + suffix;
          break;
      }
      this.isGeneratingReport = true;
      this.$api
        .post("/api/customReports/generateByKey/" + reportKey, postForm)
        .then(() => {
          this.isGeneratingReport = false;
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          console.log(error);
          this.isGeneratingReport = false;
        });
    },
    getParamsForPromotions(promotionId) {
      let fiscalYear = this.selectedFiscalYear;
      let quarter = this.quarter;
      let currency = this.currency;
      let approved = true;
      let organizations = null;
      if (this.organizations) {
        organizations = this.organizations.map(organization => {
          return { id: organization.id };
        });
      }

      return {
        filters: {
          selectedFiscalYear: fiscalYear,
          selectedFiscalQuarter: quarter < 4 ? quarter : null,
          selectedCurrency: currency,
          promotionId: promotionId,
          selectedApproved: approved,
          selectedOrganizations: organizations
        }
      };
    },
    getParamsForOrganizations(organizationId) {
      let fiscalYear = this.selectedFiscalYear;
      let quarter = this.quarter;
      let currency = this.currency;
      let approved = true;
      let organizations = [{ id: organizationId }];

      return {
        filters: {
          selectedFiscalYear: fiscalYear,
          selectedFiscalQuarter: quarter < 4 ? quarter : null,
          selectedCurrency: currency,
          selectedApproved: approved,
          selectedOrganizations: organizations,
          promotionId: null
        }
      };
    },
    getPromotionTypes() {
      return this.$api
        .post("/api/promotionTypes/search?size=100", {})
        .then(({ data }) => {
          this.filterByPromotionTypes = data.content.filter(elem => elem.name.trim() !== "Dealer Rebate");
        })
        .catch(error => {
          console.log(error);
        });
    },
    getParamsForParticipants(participantId) {
      let fiscalYear = this.selectedFiscalYear;
      let quarter = this.quarter;
      let currency = this.currency;
      let approved = true;
      let organizations = null;
      if (this.organizations) {
        organizations = this.organizations.map(organization => {
          return { id: organization.id };
        });
      }
      let participants = [{ id: participantId }];
      return {
        filters: {
          selectedFiscalYear: fiscalYear,
          selectedFiscalQuarter: quarter < 4 ? quarter : null,
          selectedCurrency: currency,
          selectedApproved: approved,
          selectedOrganizations: organizations,
          selectedParticipants: participants
        }
      };
    }
  },
  props: {
    showPromotionTypeFilter: {
      type: Boolean,
      default: false
    },
    showBusinessUnitFilter: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    claimTopPromotionOptions: {
      handler() {
        this.fetchDataForTab(this.tab);
      },
      deep: true
    },
    claimTopOrganizationOptions: {
      handler() {
        this.fetchDataForTab(this.tab);
      },
      deep: true
    },
    claimTopParticipantOptions: {
      handler() {
        this.fetchDataForTab(this.tab);
      },
      deep: true
    },
    selectedFiscalYear: {
      deep: true,
      immediate: true,
      handler() {
        if (
          this.selectedFiscalYear &&
          this.selectedFiscalYear.fiscalYearStart &&
          this.selectedFiscalYear.fiscalYearEnd
        ) {
          this.periodStart = this.selectedFiscalYear.fiscalYearStart;
          this.periodEnd = this.selectedFiscalYear.fiscalYearEnd;
          if (moment().year() == moment(this.selectedFiscalYear.fiscalYearStart).year()) {
            this.quarter = this.$client.getCurrentFiscalQuarter(this.selectedClient).number - 1;
          } else {
            this.quarter = 0;
          }
          this.setDefaultCurrency();
        }
      }
    },
    quarter: {
      handler(v) {
        if (v != null && v != 4) {
          this.periodStart = moment(this.selectedFiscalYear.fiscalYearStart)
            .add(3 * v, "M")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
          this.periodEnd = moment(this.periodStart)
            .add(3, "M")
            .add(-1, "d")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
          this.getData();
        } else {
          this.quarter = 4;
          this.periodStart = this.selectedFiscalYear.fiscalYearStart;
          this.periodEnd = this.selectedFiscalYear.fiscalYearEnd;
          this.getData();
        }
      }
    },
    currency: {
      handler(v) {
        if (!v) {
          this.setDefaultCurrency();
        }
        this.getData();
      }
    }
  },
  mounted() {
    this.getPromotionTypes().then(() => {
      this.fetchDataForTab(true);
    });
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedFiscalYear"])
  }
};
</script>
